<template>
  <div id="app">
    <guest-booking-widget :widget-id="widgetKey" :club-id="clubId" :source="source" />
  </div>
</template>

<script>
import { i18n } from '@virtuagym/ui-kit'
import GuestBookingWidget from "./views/GuestBookingWidget"

export default {
  name: 'App',
  components: {
    GuestBookingWidget
  },
  props: {
    widgetKey: String,
    clubId: Number,
    source: String,
    lang: {
      type: String,
      default: 'en'
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(v) {
        i18n.locale = v
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@virtuagym/ui-kit/style/reset";
</style>
