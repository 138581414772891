/*eslint-disable import/no-unresolved*/
import Vue from 'vue'
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'
import VgUIKit, { i18n } from '@virtuagym/ui-kit'
import * as Sentry from "@sentry/vue"
import App from './App'
import { loadCss } from './style'
import de from './translations/de.json'
import en from './translations/en.json'
import es from './translations/es.json'
import fr from './translations/fr.json'
import it from './translations/it.json'
import nl from './translations/nl.json'
import pt from './translations/pt.json'

/* istanbul ignore if */
if (process.env.VUE_APP_ENVIRONMENT !== 'test') {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT,
    dsn: "https://c17ce25a288f4ad1bf3185d5757ae767@o104172.ingest.sentry.io/5533394",
    logErrors: true,
    integrations(integrations) {
      // Filter out Sentry's default global exception handlers
      const integ = integrations.filter((i) => i.name !== 'GlobalHandlers' && i.name !== 'ReportingObserver')
      return integ
    },
    beforeSend(event) {
      // Set a custom tag to classify by source file origin. We might be able to use this data to
      // eventually ignore exceptions not coming from our app's code.
      const vgScriptSourceRegex = /^https:\/\/static(-test)?.virtuagym.com\/.*$/
      const stackTrace = event?.exception?.values[0]?.stacktrace?.frames
      // eslint-disable-next-line no-param-reassign
      event.tags = event.tags || {}

      if (process.env.VUE_APP_ENVIRONMENT === 'local') {
        // eslint-disable-next-line no-param-reassign
        event.tags.script_origin = 'Webpack Local'
        return event
      }

      if (!stackTrace) {
        // eslint-disable-next-line no-param-reassign
        event.tags.script_origin = 'Unknown'
        return event
      }

      const source = stackTrace[stackTrace.length - 1].filename
      if (vgScriptSourceRegex.test(source)) {
        // eslint-disable-next-line no-param-reassign
        event.tags.script_origin = 'Virtuagym'
        return event
      }

      // Ignore events that didn't fit any of the above conditions
      return null
    }
  })
}

// Do the app set up in a try catch so that Sentry can notify us
// of initialization errors as well.
// Run time App errors are handled in App.vue
try {
  // For production build we load the css manually into the client page.
  // For Development this is done automatically by webpack.
  /* istanbul ignore if */
  if (process.env.NODE_ENV === 'production') {
    loadCss()
  }

  Vue.config.productionTip = false
  Vue.use(vueCustomElement)
  Vue.use(VgUIKit, {
    translations: {
      de, en, es, fr, it, nl, pt
    }
  })

  // NOTE: configurations that would have normally gone on
  // the initialization here through "new Vue" are now
  // meant to be added as properties of App. Eg. App.store = store
  App.i18n = i18n
  Vue.customElement('vg-guest-booking', App)
} catch (e) {
  /* istanbul ignore if */
  if (process.env.VUE_APP_ENVIRONMENT !== 'test') {
    Sentry.captureException(e)
  }
  console.error(e)
}
