<template>
  <div class="guest-booking-widget">
    <div v-if="loading">
      <vg-loading />
    </div>
    <div v-else>
      <vg-steps
        v-if="showWidget"
        :current-step="stepsCurrentTemplate"
        :show-steps-indicator="true"
        :steps="stepsItems"
        :color="colors.colorTheme"
      >
        <template slot="bookingSelect">
          <div class="guest-booking-widget--header">
            <div class="guest-booking-widget--header--title">
              <span>{{ selectedActivityTitle }}</span>
              <span class="guest-booking-widget--header--side-bullet">&#8226;</span>
              <span class="guest-booking-widget--header--side-information">{{ $t('common.time.shortMinutes', { minutes: selectedActivityDuration }) }}</span>
            </div>
            <span class="guest-booking-widget--subtitle">{{ $t('widget.bookingSelect.subtitle') }}</span>
          </div>

          <div class="guest-booking-widget--body">
            <div class="guest-booking-widget--body--booking-select">
              <vg-date-picker-slider :date.sync="bookingSelectedDay" :color="colors.colorTheme" />
              <div v-if="loadingAvailableTime">
                <vg-loading />
              </div>
              <div v-else class="guest-booking-widget--body--booking-select--time">
                <div v-if="bookingSelect.availableTimeSlot.length !==0">
                  <vg-list-select
                    id="widget-timeslot"
                    :items="bookingSelect.availableTimeSlot"
                    :selected-item.sync="bookingSelectedTime"
                    :color="colors.colorTheme"
                  />
                </div>
                <div v-else class="guest-booking-widget--body--booking-select--time--no-result">
                  <div class="no-result-icon">
                    <svgicon :color="colors.colorTheme" name="timer_off_twotones" width="48" height="48" />
                  </div>
                  <div class="no-result-label">
                    <span>{{ $t('errors.noAvailableTime') }}</span>
                  </div>
                </div>
              </div>

              <div v-if="bookingSelect.availableInstructor.length !==0" class="guest-booking-widget--body--booking-select--instructor">
                <div class="guest-booking-widget--body--booking-select--instructor--title">
                  {{ $t('widget.bookingSelect.instructor') }}
                </div>
                <vg-list-select
                  id="widget-instructor"
                  :items="bookingSelect.availableInstructor"
                  :selected-item.sync="bookingSelectedInstructor"
                  :color="colors.colorTheme"
                />
              </div>
            </div>
          </div>

          <div class="guest-booking-widget--footer">
            <vg-button
              :disabled="bookingSelect.nextButtonDisabled"
              :color="colors.colorTheme"
              :upper-case="false"
              :title="$t('buttons.nextStep')"
              kind="rounded-filled"
              data-cy="booking-select-next-button"
              @click="handleNextStep('bookingSelect')"
            />
          </div>
        </template>

        <template slot="bookingForm">
          <div class="guest-booking-widget--header">
            <div class="guest-booking-widget--header--title">
              <span>{{ $t('widget.bookingForm.title') }}</span>
            </div>
            <span class="guest-booking-widget--subtitle">{{ $t('widget.bookingForm.subtitle') }}</span>
          </div>

          <div class="guest-booking-widget--body">
            <vg-notice-box :visible="bookingErrorMessage !== null" kind="error" class="guest-booking-widget--body--error-message">
              {{ bookingErrorMessage }}
            </vg-notice-box>
            <div class="guest-booking-widget--body--booking-form">
              <div class="guest-booking-widget--body--booking-form--item">
                <div class="guest-booking-widget--body--booking-form--label">
                  {{ $t('widget.bookingForm.firstName') }}
                  <span class="guest-booking-widget--body--booking-form--label--required">*</span>
                </div>
                <vg-text-field
                  v-model="bookingForm.firstName"
                  :error-message="typedFields.includes('firstName') && requiredField(bookingForm.firstName)"
                  placeholder="E.g. Brad"
                  data-cy="guest-first-name"
                  required
                />
              </div>
              <div class="guest-booking-widget--body--booking-form--item">
                <div class="guest-booking-widget--body--booking-form--label">
                  {{ $t('widget.bookingForm.lastName') }}
                  <span class="guest-booking-widget--body--booking-form--label--required">*</span>
                </div>
                <vg-text-field
                  v-model="bookingForm.lastName"
                  :error-message="typedFields.includes('lastName') && requiredField(bookingForm.lastName)"
                  :minimalist="true"
                  placeholder="E.g. Fit"
                  data-cy="guest-last-name"
                  required
                />
              </div>
              <div class="guest-booking-widget--body--booking-form--item">
                <div class="guest-booking-widget--body--booking-form--label">
                  {{ $t('widget.bookingForm.email') }}
                  <span class="guest-booking-widget--body--booking-form--label--required">*</span>
                </div>
                <vg-text-field
                  v-model="bookingForm.email"
                  :error-message="typedFields.includes('email') && (requiredField(bookingForm.email) || validEmail)"
                  :minimalist="true"
                  placeholder="E.g. bradfit@mail.com"
                  data-cy="guest-email"
                  required
                />
              </div>
              <div class="guest-booking-widget--body--booking-form--item">
                <div class="guest-booking-widget--body--booking-form--label">
                  {{ $t('widget.bookingForm.phoneNumber') }}
                  <span class="guest-booking-widget--body--booking-form--label--required">*</span>
                </div>
                <vg-text-field
                  v-model="bookingForm.phone"
                  :error-message="typedFields.includes('phone') && (requiredField(bookingForm.phone) || validNumber)"
                  :minimalist="true"
                  placeholder="E.g. +316212345678"
                  data-cy="guest-phone"
                />
              </div>
              <div class="guest-booking-widget--body--booking-form--full" :hidden="widgetInfo.noteTitle == null || widgetInfo.noteTitle == ''">
                <div class="guest-booking-widget--body--booking-form--label">
                  {{ widgetInfo.noteTitle }}
                </div>
                <vg-textarea
                  v-model="bookingForm.notes"
                  :text.sync="bookingForm.notes"
                  minimalist
                  data-cy="guest-notes"
                />
              </div>
              <div v-if="widgetInfo.enableOptIn" class="guest-booking-widget--body--booking-form--full">
                <vg-check-box
                  v-model="acceptWidgetOptIn"
                  :label="widgetInfo.optInText"
                  data-cy="widget-opt-in-checkbox"
                />
              </div>
            </div>
          </div>

          <div class="guest-booking-widget--footer">
            <vg-button
              :color="colors.backButtonColor"
              :border-color="colors.backButtonBorderColor"
              :upper-case="false"
              :title="$t('buttons.back')"
              kind="outline-rounded"
              data-cy="booking-form-back-button"
              @click="handleBackStep()"
            />
            <vg-button
              :color="colors.colorTheme"
              :disabled="disableBookNowButton"
              :upper-case="false"
              :title="$t('buttons.bookNow')"
              class="guest-booking-widget--footer--next-button"
              kind="rounded-filled"
              data-cy="book-now-button"
              @click="handleBookNow()"
            />
          </div>
        </template>

        <template slot="bookingComplete">
          <div class="guest-booking-widget--header">
            <div class="guest-booking-widget--header--title">
              <span>{{ $t('widget.bookingComplete.title') }}</span>
            </div>
            <span class="guest-booking-widget--subtitle">{{ $t('widget.bookingComplete.subtitle', {participantName}) }}</span>
          </div>

          <div class="guest-booking-widget--body">
            <div class="guest-booking-widget--body--booking-complete">
              <vg-badge-item
                :bold="true"
                :label="selectedActivityTitle"
                :label-subtitle="selectedInstructorName"
                :badge-image-source="selectedInstructorAvatar"
                :helper-text="selectedDateTimeReadableString"
                badge-size="medium"
                label-size="H400"
                type="image"
                badge-icon="image-placeholder"
              />
            </div>
          </div>
        </template>
      </vg-steps>

      <div v-if="!showWidget && widgetInitializationError" class="guest-booking-widget--loading-error">
        <div class="no-result-icon">
          <svgicon :color="colors.errorIcon" name="calendar_error_twotones" width="48" height="48" />
        </div>
        <div class="no-result-header">
          <span>{{ $t('errors.loadingWidget.header') }}</span>
        </div>
        <div class="no-result-label">
          <span v-if="!widgetEnabled">{{ $t('errors.widgetDisabled.subtitle') }}</span>
          <span v-else>{{ $t('errors.loadingWidget.subtitle') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEmail from "validator/lib/isEmail"
import {
  VgButton,
  VgBadgeItem,
  VgSteps,
  VgDatePickerSlider,
  VgListSelect,
  VgTextField,
  VgLoading,
  VgNoticeBox,
  DateTimeWrapper,
  Color,
  VgTextarea,
  VgCheckBox
} from '@virtuagym/ui-kit'
import axios from '../utils/axios'
import '@virtuagym/ui-kit/icons'

const BOOKING_CREATED = 201
const BOOKING_FAIL_REASON_FULLY_BOOKED = 103
const BOOKING_FAIL_REASON_ALREADY_BOOKED = 102
const BOOKING_FAIL_REASON_NOT_AVAILABLE = 101
const BOOKING_FAIL_NOT_FOUND = 404
const BOOKING_FAIL_INVALID = 400
const WIDGET_DISABLED = 402
const GUEST_INVALID_EMAIL = 'Invalid guest email'

export default {
  name: 'GuestBookingWidget',
  components: {
    VgNoticeBox,
    VgSteps,
    VgButton,
    VgDatePickerSlider,
    VgListSelect,
    VgTextField,
    VgLoading,
    VgBadgeItem,
    VgTextarea,
    VgCheckBox
  },
  props: {
    widgetId: String,
    clubId: Number,
    source: String
  },

  data() {
    return {
      loading: true,
      loadingAvailableTime: true,
      showWidget: false,
      acceptWidgetOptIn: false,
      colors: {
        colorTheme: Color.palette.theme.secondary,
        backButtonColor: Color.palette.neutral600,
        backButtonBorderColor: Color.palette.neutral600,
        errorIcon: Color.palette.theme.errorAccent
      },
      widgetInfo: {
        availableActivities: [],
        timezone: null,
        dateFormat: null,
        use24HourFormat: true,
        noteTitle: null,
        enableOptIn: false,
        optInText: null
      },
      stepsItems: ['bookingSelect', 'bookingForm', 'bookingComplete'],
      stepsCurrentTemplate: 'bookingSelect',
      bookingSelectedDay: DateTimeWrapper.now().toIsoFullDateTimeString(),
      bookingSelectedTime: null,
      bookingSelectedEndTime: null,
      bookingSelectedInstructor: null,
      bookingSelectedActivity: {},
      bookingSelect: {
        availableInstructor: [],
        availableTimeSlot: [],
        nextButtonDisabled: true,
        selectedDate: DateTimeWrapper.now(),
        selectedActivity: null,
      },
      bookingForm: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        notes: null,
      },
      bookingErrorMessage: null,
      widgetInitializationError: false,
      widgetEnabled: true,
      typedFields: [],
      processingBooking: false,
      showGuestInvalidEmail: false
    }
  },

  computed: {
    participantName() {
      return `${this.bookingForm.firstName} ${this.bookingForm.lastName}`
    },

    selectedActivityDuration() {
      if (!this.bookingSelectedActivity) {
        return 0
      }
      return parseInt(this.bookingSelectedActivity.duration, 10) / 60
    },

    selectedDateTimeReadableString() {
      if (!this.bookingSelectedTime) {
        return null
      }
      const dateTimeString = new DateTimeWrapper(
        this.bookingSelectedTime.value,
        this.widgetInfo.timezone,
        this.widgetInfo.dateFormat,
        this.widgetInfo.use24HourFormat
      ).getHumanReadableString(true)

      const endTimeString = this.bookingSelectedEndTime ? `- ${new DateTimeWrapper(
        this.bookingSelectedEndTime,
        this.widgetInfo.timezone,
        this.widgetInfo.dateFormat,
        this.widgetInfo.use24HourFormat
      ).getUserFormatTimeString()}` : null

      return `${dateTimeString} ${endTimeString}`
    },

    selectedInstructorName() {
      if (!this.bookingSelectedInstructor) {
        return null
      }
      return this.bookingSelectedInstructor.label
    },

    selectedInstructorAvatar() {
      if (!this.bookingSelectedInstructor) {
        return null
      }
      return this.bookingSelectedInstructor.avatar
    },

    selectedActivityTitle() {
      if (!this.bookingSelectedActivity) {
        return null
      }
      return this.bookingSelectedActivity.title
    },

    validNumber() {
      if (this.bookingForm.phone && Number.isNaN(Number(this.bookingForm.phone))) {
        return this.$t("errors.notValidNumber")
      }
      return false
    },

    validEmail() {
      if ((this.bookingForm.email && !isEmail(this.bookingForm.email)) || this.showGuestInvalidEmail) {
        return this.$t("errors.emailNotValid")
      }
      return false
    },

    disableBookNowButton() {
      if (
        !this.bookingForm.firstName
        || !this.bookingForm.lastName
        || !this.bookingForm.email
        || !this.bookingForm.phone
        || this.validEmail !== false
        || (this.widgetInfo.enableOptIn && !this.acceptWidgetOptIn)
        || this.validNumber !== false
        || this.processingBooking
      ) {
        return true
      }
      return false
    }
  },

  watch: {
    bookingSelectedDay() {
      this.bookingSelect.selectedDate = new DateTimeWrapper(this.bookingSelectedDay)
      this.fetchTimeslotInformation()
    },

    bookingSelectedTime() {
      const staffs = this.bookingSelectedTime && this.bookingSelectedTime.staff

      if (staffs) {
        const availableStaffs = []
        staffs.forEach((staff) => {
          const item = {
            avatar: staff.avatar,
            label: staff.name,
            value: staff.staff_guid
          }
          availableStaffs.push(item)
        })
        this.bookingSelect.availableInstructor = availableStaffs

        if (availableStaffs?.length === 1) {
          const onlyStaff = availableStaffs[0]
          this.bookingSelectedInstructor = onlyStaff
        }
      }
    },

    bookingSelectedInstructor() {
      if (this.bookingSelectedInstructor) {
        this.bookingSelect.nextButtonDisabled = false
      }
    },

    widgetId() {
      this.fetchWidgetInformation()
    },

    bookingForm: {
      handler(bookingForm) {
        Object.keys(bookingForm).forEach((bookingFormKey) => {
          if (bookingForm[bookingFormKey]?.length > 0) {
            if (!this.typedFields.includes(bookingFormKey)) {
              this.typedFields.push(bookingFormKey)
            }
          }
        })
      },
      deep: true
    }
  },

  beforeMount() {
    this.fetchWidgetInformation()
  },

  methods: {
    requiredField(value) {
      if ((value === null || value === undefined || value === '' || (value && value.length === 0))) {
        return this.$t("errors.fieldRequired")
      }
      return false
    },

    handleNextStep(currentStep) {
      const amountOfSteps = this.stepsItems.length
      const currentStepIndex = this.stepsItems.indexOf(currentStep)

      if (currentStepIndex + 1 < amountOfSteps) {
        this.stepsCurrentTemplate = this.stepsItems[currentStepIndex + 1]
      }
    },

    handleBackStep() {
      const previousStepIndex = this.stepsItems.indexOf(this.stepsCurrentTemplate) - 1

      if (previousStepIndex >= 0) {
        this.stepsCurrentTemplate = this.stepsItems[previousStepIndex]
      }
      this.resetForm()
    },

    async handleBookNow() {
      if (this.processingBooking) return

      this.processingBooking = true
      this.bookingErrorMessage = null

      const url = `/public/v3/clubs/${this.clubId}/schedule/widgets/${this.widgetId}/events`

      const payload = {
        activity_id: this.bookingSelect.selectedActivity,
        datetime_start: this.bookingSelectedTime.value,
        guest: {
          first_name: this.bookingForm.firstName,
          email: this.bookingForm.email,
          last_name: this.bookingForm.lastName,
          phone: this.bookingForm.phone,
        },
        notes: this.bookingForm.notes,
        staff_guid: this.bookingSelectedInstructor.value,
        source: this.source ? this.source : ""
      }

      try {
        const response = await axios.post(url, payload)
        const { status, data } = response

        if (status === BOOKING_CREATED) {
          this.bookingSelectedEndTime = `${data.response.day}T${data.response.time_end}`
          this.handleNextStep('bookingForm')
        }

        const { reason } = data.response
        switch (reason) {
          case BOOKING_FAIL_REASON_FULLY_BOOKED: {
            this.bookingErrorMessage = this.$t('errors.booking.fullyBooked')
            break
          }
          case BOOKING_FAIL_REASON_ALREADY_BOOKED: {
            this.bookingErrorMessage = this.$t('errors.booking.guestAlreadyBooked')
            break
          }
          case BOOKING_FAIL_REASON_NOT_AVAILABLE: {
            this.bookingErrorMessage = this.$t('errors.booking.notAvailable')
            break
          }
          default:
            this.bookingErrorMessage = this.$t('errors.somethingWentWrong')
            break
        }
      } catch (e) {
        if (!e.response || !e.response.status) {
          this.bookingErrorMessage = this.$t('errors.somethingWentWrong')
        }
        if (e.response.status === WIDGET_DISABLED) {
          this.widgetInitializationError = true
          this.showWidget = false
          this.widgetEnabled = false
        } else if (e.response.data.status === GUEST_INVALID_EMAIL) {
          this.showGuestInvalidEmail = true
        } else if (e.response.status === BOOKING_FAIL_NOT_FOUND || e.response.status === BOOKING_FAIL_INVALID) {
          this.bookingErrorMessage = this.$t('errors.booking.notAvailable')
        } else {
          this.bookingErrorMessage = this.$t('errors.somethingWentWrong')
        }
      } finally {
        this.processingBooking = false
      }
    },

    async fetchWidgetInformation() {
      this.widgetInitializationError = false
      this.showWidget = false
      this.loading = true
      const url = `/public/v3/clubs/${this.clubId}/schedule/widgets/${this.widgetId}`

      try {
        const response = await axios.get(url)
        const { data } = response
        this.widgetInfo.availableActivities = data.data.activities
        this.bookingSelect.selectedActivity = data.data.activities[0].activity_id
        this.colors.colorTheme = data.data.color
        this.widgetInfo.timezone = data.data.datetime_settings.timezone
        this.widgetInfo.dateFormat = data.data.datetime_settings.date_format
        this.widgetInfo.use24HourFormat = parseInt(data.data.datetime_settings.use_24_hour_format, 10)
        this.widgetInfo.noteTitle = data.data.note_title
        this.widgetInfo.enableOptIn = data.data.enable_opt_in
        this.widgetInfo.optInText = data.data.opt_in_text || this.$i18n.t("widget.optInDefaultText")

        // todo: remove this when we already have multiple activity, this should be set after selecting activity
        this.bookingSelectedActivity = {
          title: this.widgetInfo.availableActivities[0].title,
          duration: this.widgetInfo.availableActivities[0].duration
        }
        this.showWidget = true
      } catch (e) {
        this.widgetInitializationError = true
        if (e.response.status === WIDGET_DISABLED) {
          this.widgetEnabled = false
        }
        console.error(e)
      }

      this.loading = false
      if (this.showWidget) {
        this.fetchTimeslotInformation()
      }
    },

    async fetchTimeslotInformation() {
      this.bookingSelect.nextButtonDisabled = true
      this.bookingSelectedTime = null
      this.bookingSelectedInstructor = null
      this.bookingSelect.availableInstructor = []
      this.loadingAvailableTime = true
      const selectedDateIso = new DateTimeWrapper(this.bookingSelect.selectedDate, this.widgetInfo.timezone).toIsoDateString()
      const url = `/public/v3/clubs/${this.clubId}/schedule/widgets/${this.widgetId}/activities/${this.bookingSelect.selectedActivity}/timeslots?day=${selectedDateIso}`

      try {
        const response = await axios.get(url)
        const { data } = response
        const timeslotsData = data.data
        const availableTimeslotItems = []

        Object.keys(timeslotsData).forEach((startTime) => {
          const dateTimeStart = `${selectedDateIso}T${startTime}:00`

          const timeslotData = {
            label: new DateTimeWrapper(
              dateTimeStart,
              this.widgetInfo.timezone,
              this.widgetInfo.dateFormat,
              this.widgetInfo.use24HourFormat
            ).getUserFormatTimeString(),
            value: dateTimeStart,
            staff: timeslotsData[startTime].staff_members,
          }
          availableTimeslotItems.push(timeslotData)
        })

        this.bookingSelect.availableTimeSlot = availableTimeslotItems
      } catch (e) {
        console.error(e)
        this.widgetInitializationError = true
        this.showWidget = false
        if (e.response.status === WIDGET_DISABLED) {
          this.widgetEnabled = false
        }
      }
      this.loadingAvailableTime = false
    },

    resetForm() {
      this.bookingSelectedTime = null
      this.bookingSelectedInstructor = null
      this.bookingSelect.availableInstructor = []
      this.bookingSelect.nextButtonDisabled = true
      this.bookingForm.firstName = null
      this.bookingForm.lastName = null
      this.bookingForm.email = null
      this.bookingForm.phone = null
      this.bookingErrorMessage = null
      this.bookingSelectedDay = DateTimeWrapper.now().toIsoFullDateTimeString()
      this.bookingForm.notes = null
    }
  }
}
</script>

<style lang="scss">
@import './style';
</style>
