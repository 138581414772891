/* istanbul ignore next */
export const loadCss = () => {
  const cssID = 'vg-widget-styling'
  if (document.getElementById(cssID)) {
    return
  }

  // Get the styling for the widget from the server
  const baseUrl = process.env.VUE_APP_SERVER_URL
  const head = document.getElementsByTagName('head')[0]
  const link = document.createElement('link')
  link.id = cssID
  link.rel = 'stylesheet'
  link.type = 'text/css'
  link.href = `${baseUrl}/dist/css/app.css`
  head.appendChild(link)
}
